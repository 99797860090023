<template>
  <div>
    <div class="text-center">
      <b-card-text class="mt-2 h4 color-inherit text-reset">
        Table Settings
      </b-card-text>
    </div>

    <b-list-group class="mt-3 rounded-0">
      <b-list-group-item
        class="bg-transparent"
      >
        <slot />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCardText,
  },
  data() {
    return {
      sidebarItems: [
        { title: 'Dashboard', icon: 'GridIcon' },
        { title: 'Analytics', icon: 'ActivityIcon' },
        { title: 'History', icon: 'RefreshCwIcon' },
        { title: 'Configuration', icon: 'SettingsIcon' },
        { title: 'Profile', icon: 'UserIcon' },
      ],
    }
  },
}
</script>
