<template>
  <section id="dashboard">
    <b-card>
      <b-card-header>
        <b-card-title tag="h4">
          Our License Usage
        </b-card-title>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle.sidebar-right
          variant="outline-primary"
        >
          <feather-icon icon="SettingsIcon" />
        </b-button>
        <b-sidebar
          id="sidebar-right"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <sidebar-content>
            <v-select
              v-model="selectedFields"
              multiple
              label="label"
              small
              :options="fields"
            />
          </sidebar-content>
        </b-sidebar>
      </b-card-header>
      <b-card-Body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="6"
            />
          </b-row>
        </div>
        <b-table
          bordered
          sticky-header
          hover
          :items="repData"
          :fields="visibleFields"
          responsive="sm"
          small
          class-name="mb-0 pb-1"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class-name="text-center text-primary my-2">
              <b-spinner class-name="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>

        <h5 className="mt-3">
          Single License
        </h5>
        <ul className="ps-25 ms-1">
          <li>
            You have rights to use our product for your personal or client
            project.
          </li>
          <li>
            You can modify our product as per your needs and use it for your
            personal or client project.
          </li>
        </ul>
        <b-card-text class-name="mb-2 pb-75">
          With Single License you will be able to use our product for yourself
          or your client project for 1 time. If you want to use it for multiple
          times, you need to buy another regular license every time. Ownership
          and Copyright of our template will stay with ThemeSelection after
          purchasing single license. That means you are allowed to use our
          template in your project and use for one client or yourself,
        </b-card-text>
      </b-card-Body>
    </b-card>
  </section>
</template>
<script>
import {
  onUnmounted, ref, computed, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BTable,
  BSpinner,
  VBToggle,
  BSidebar,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import reportsStoreModule from '../reportsStoreModule'
import SidebarContent from './SidebarContent.vue'

const data = []
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BTable,
    BSpinner,
    vSelect,
    BSidebar,
    SidebarContent,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    const isBusy = ref(true)
    const fields = ref([
      // A column that needs custom formatting
      {
        key: 'client_name', label: 'ФИО', visible: true,
      },
      { key: 'debt', label: 'Долг $', visible: true },
      // { key: 'days', label: '# дней', visible: true },
      { key: 'weight', label: 'Вес, т', visible: true },
      { key: 'sales', label: 'Продажа $', visible: true },
      { key: 'payment', label: 'Оплата $', visible: true },
      { key: 'income', label: 'Доход $', visible: true },
    ])
    const repData = ref([])
    const selectedFields = ref([])
    selectedFields.value = fields.value

    const DASH_REPORT_STORE_MODULE_NAME = 'dashboard-reports'
    if (!store.hasModule(DASH_REPORT_STORE_MODULE_NAME)) store.registerModule(DASH_REPORT_STORE_MODULE_NAME, reportsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASH_REPORT_STORE_MODULE_NAME)) store.unregisterModule(DASH_REPORT_STORE_MODULE_NAME)
    })
    store.dispatch('dashboard-reports/fetchReport1').then(response => {
      if (
        response.data.status !== undefined && response.data.status === 'success'
      ) {
        repData.value = response.data.result.map(item => {
          const newItem = item
          return newItem
        })
        isBusy.value = false
      }
    })
    const visibleFields = computed(() => {
      const sortingArr = fields.value.map(a => a.key)
      return selectedFields.value.sort((a, b) => sortingArr.indexOf(a.key) - sortingArr.indexOf(b.key))
    })
    watch(selectedFields, newValue => {
      console.log(newValue)
    },
    { immediate: true })

    return {
      data,
      fields,
      repData,
      isBusy,
      visibleFields,
      selectedFields,
    }
  },
}
</script>
